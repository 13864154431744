/* eslint-disable react/jsx-key */
import { Button, UIHeading1, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './TourComponent.css';

export interface ITourComponentProps {
  tourBannerImageUrl: string;
  children?: React.ReactNode;
  isExternal?: boolean;
  noFullscreen?: boolean;
  onClick: () => void;
}

export const TourComponent = ({ onClick, tourBannerImageUrl, isExternal }: ITourComponentProps) => {
  return (
    <div className={styles['tour']} id="tourBlock">
      <UIHeading1>3D-тур по квартире</UIHeading1>
      <div className={styles['content']}>
        <div
          className={styles['layout']}
          style={{ backgroundImage: `url(${tourBannerImageUrl})` }}
          data-tour="layout"
          data-testid="tour"
          onClick={onClick}
        >
          <div className={styles['text']}>
            <UIText1 userSelect="none" color="text-inverted-default">
              {isExternal ? 'Погуляйте по комнатам,' : 'Погуляйте по комнатам, чтобы оценить планировку.'}
            </UIText1>
            <UIText1 userSelect="none" color="text-inverted-default">
              {isExternal ? 'чтобы оценить планировку и ремонт.' : 'Мебель использована для примера.'}
            </UIText1>
          </div>
          <Button theme="fill_white_primary" size="XS">
            Погулять по квартире
          </Button>
        </div>
      </div>
    </div>
  );
};

TourComponent.displayName = 'TourComponent';
